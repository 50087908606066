@import url('https://fonts.googleapis.com/css2?family=Bebas+Neue&family=Days+One&family=Montserrat:wght@224;500;600;700;900&display=swap');
body {
  margin: 0;
  font-family: 'Montserrat', sans-serif,-apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.container{
  gap: 2vw;
}

h3{
  text-align: left;
  font-weight: 900 !important;

}

h4{

  font-weight: 500 !important;

}

.header-top{
  width: 6vw;

}

.link_artista{

  text-decoration: none;
  color: inherit;
}

.posicion{
  display: flex !important;
  flex-direction: column;
  border-radius: 5px;
  color: #f3f3f3;
  text-align: center;
  justify-content: center;
  height: 100%;
  align-items: center;
  z-index: 1000;

}

.row-top{

  align-items: center !important;
  justify-content: center !important;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.music-card {
  margin-bottom: 4%;
  border-radius: 6px;
  margin-top: 5%;
  background: #12b3e886;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.4);
  overflow: hidden;
  position: relative;
  height: 295px;
  max-width: 295px;
  background-image:
radial-gradient(at 98% 1%, hsla(189,100%,53%,1) 0px, transparent 50%),
radial-gradient(at 7% 6%, hsla(217,70%,35%,1) 0px, transparent 50%),
radial-gradient(at 22% 89%, hsla(235,100%,38%,1) 0px, transparent 50%),
radial-gradient(at 95% 97%, hsla(195,100%,50%,1) 0px, transparent 50%);
}
.music-card > .image img {
  position: absolute;
  opacity: 0.5;
  z-index: 0;
  height: 100%;
  width: 100%;
}

.music-card > .image:after {
  height: 100px;
  content: '';
  top: 100vh;
  position: absolute;
  width: 100%;
  z-index: 1;
  /* background: linear-gradient(180deg, rgba(0, 20, 150, 0), #1b4c9a); */
}
.music-card > .wave {
  position: absolute;
  height: 750px;
  width: 750px;
  opacity: 0.6;
  left: 0;
  top: 0;
  margin-left: 20%;
  margin-top: -130%;
  border-radius: 40%;
  background: radial-gradient(#1b4c9abe, #12b3e8);
  animation: spin 150000ms infinite linear;
}
.music-card > .wave:nth-child(2) {
  top: 10px;
  animation: spin 100000ms infinite linear;
}
.music-card > .wave:nth-child(3) {
  top: 10px;
  animation: spin 100000ms infinite linear;
}
.music-card > .info {
  /* margin-top: 60px; */
  position: absolute;
  bottom: 25px;
  left: 0;
  right: 0;
  text-align: center;
  background-color: rgba(51, 51, 51, 0.318);
}
.music-card > .info > .title {
  font-size: 1.3em;
  font-weight: 400;
  color: #f3f3f3;
  margin-bottom: 8px;
}
.music-card > .info > .artist {
  color: #d3d3d3;
  font-size: 0.8em;
  letter-spacing: 0.08em;
  text-transform: uppercase;
}
.position {
  position: absolute;
  color: #f3f3f3;
  text-align: center;
  font-size: 1.7em;
  font-weight: 900;
  letter-spacing: 0.08em;
  text-transform: uppercase;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.4);
  z-index: 15;
  border-radius: 0 15% 15% 15%;
  background-color: #fbc500;
  padding: 0.5vw;
}
